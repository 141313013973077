interface IHmt {
  category: string;
  action?: any;
  label?: any;
  value?: any;
  nodeid?: any;
}

interface ISearch {
  entry?: string;
  type?: string;
  source?: string;
}
export const downloadCount = (search: ISearch, varsHmt: IHmt) => {
  const { category, label, value, nodeid, action } = varsHmt;
  const _hmt = window._hmt || [];
  // 总量
  _hmt.push(['_trackEvent', '客户端总下载量', '下载', label, value, nodeid]);
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    _hmt.push(['_trackEvent', '移动端总下载量', '下载', label, value, nodeid]);
  } else {
    _hmt.push(['_trackEvent', 'PC端总下载量', '下载', label, value, nodeid]);
  }

  // 下载页面
  _hmt.push(['_trackEvent', category, action || '下载', label, value, nodeid]);

  if (search.entry === 'wechatMp') {
    if (search.type === 'code') {
      _hmt.push(['_trackEvent', '通过小程序二维码下载量', '下载', label, value, nodeid]);
    } else if (search.type === 'link') {
      _hmt.push(['_trackEvent', '通过小程序链接下载量', '下载', label, value, nodeid]);
    }
  }

  if (category === 'Android下载量') {
    if (search.source === 'android-wx') {
      _hmt.push(['_trackEvent', '政', '微信下载Android']);
    } else if (search.source === 'android-browser') {
      _hmt.push(['_trackEvent', '政', '浏览器下载Android']);
    }
  }
};
export const trackEvent = (event: string, operate: string, label?: string) => {
  const { _hmt = [] } = window;
  _hmt.push(['_trackEvent', event, operate, label || '']);
};
