import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'antd';
import s from './index.module.scss';
import cn from 'classnames';
import Image from 'next/image';
import { trackEvent } from 'utils/statics';
import { joinUsUrl, liveTreatyUrl, adminUrl } from 'constants/index';
import { handleZixunClick } from 'utils/index';
import CustomLink from 'components/link';

function Header() {
  const [upBtnVisible, setUpBtnVisible] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      try {
        requestAnimationFrame(() => {
          setUpBtnVisible(scrollY > 100);
        });
      } catch (error) {
        setUpBtnVisible(scrollY > 100);
      }
    };
  }, []);
  const onScrollTop = () => {
    window.scrollTo(0, 0);
  };

  const onShowEerwei = () => {
    Modal.confirm({
      title: null,
      icon: null,
      content: (
        <div className={s.erweiContainer}>
          <div className={s.erwei}>
            <Image src="/v2/images/footer/wechat-logo.png" alt="wechat-logo" layout="fill" />
          </div>
          <div>保存或扫描二维码</div>
          <div className={s.follow}>关注小鱼易连微信公众号</div>
        </div>
      ),
      className: s.confirmContainer,
      okText: '确定',
      cancelText: '',
      okCancel: false,
      width: 'calc(100% - 100px)',
      bodyStyle: { marginTop: 8 },
      centered: true,
      okButtonProps: { className: s.confirmBtn },
    });
  };

  const pcFooterList = [
    {
      title: '关于我们',
      items: [
        { text: '公司介绍', href: '/about/about' },
        { text: '公司荣誉', href: '/about/about#honer' },
        { text: '公司新闻', href: `/news` },
        { text: '联系我们', href: '/about/contact' },
        { text: '加入我们', href: joinUsUrl, isJump: true },
      ],
    },
    {
      title: '下载',
      items: [
        { text: 'Windows客户端下载', href: `/download` },
        { text: 'Mac客户端下载', href: `/download` },
        { text: 'Android客户端下载', href: `/download` },
        { text: 'iOS客户端下载', href: `/download` },
        { text: 'Linux客户端下载', href: `/download` },
      ],
    },
    {
      title: '销售',
      items: [
        { text: '立即试用', href: `/try` },
        { text: '联系销售', href: `/contact` },
        { text: '合作伙伴', href: `/partner` },
      ],
    },
    {
      title: '支持',
      items: [
        { text: '支持中心', href: '/support/faq' },
        { text: '企业管理员', href: adminUrl, isJump: true },
        { text: '直播公约', href: liveTreatyUrl, isJump: true },
        { text: '在线咨询', href: '', handleClick: handleZixunClick },
      ],
    },
  ];

  const mobileFooterList = [
    [
      { text: '公司介绍', href: '/about/about' },
      { text: '公司荣誉', href: '/about/about#honer' },
      { text: '联系我们', href: `/about/contact` },
      { text: '加入我们', href: joinUsUrl, isJump: true },
    ],
    [
      { text: '下载中心', href: '/download' },
      { text: '支持中心', href: '/support/faq' },
      { text: '直播公约', href: liveTreatyUrl, isJump: true },
      { text: '在线咨询', href: '', handleClick: handleZixunClick },
    ],
  ];

  return (
    <>
      <div className={s['footer']}>
        <div className={s['footer-container']}>
          <div className={s['footer-row']}>
            <Row justify="center">
              <Col md={{ span: 20 }} lg={{ span: 20 }} xl={{ span: 21 }} xxl={{ span: 16 }} xs={{ span: 24 }}>
                <Row justify="space-around">
                  {pcFooterList.map((colItem, index) => {
                    return (
                      <Col span={6} key={index}>
                        <div className={s['item-group']}>
                          <div className={s['item-title']}>{colItem.title}</div>
                          <ul className={s['item-list']}>
                            {colItem.items.map((item, index) => {
                              return (
                                <li className={s['item']} key={index}>
                                  <CustomLink isNextLink={false} href={item.href} needTarget={item.isJump} onClick={item.handleClick}>
                                    {item.text}
                                  </CustomLink>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </div>

          <div className={s['footer-img']}>
            <div className={s['footer-wechat']}>
              <img className={s['footer-weixin']} src="/v2/images/footer/wechat-hover.png" alt="footer-wechat" />
              <div className={s['wechat']}>
                <div className={s['light-pc']}>
                  <div className={s['wechat-img']}>
                    <Image src="/v2/images/footer/wechat-logo.png" alt="xy-wechat" layout="fill" />
                  </div>
                  <p>扫描二维码关注我们</p>
                </div>
              </div>
            </div>
          </div>

          <p className={s['footer-phone']} style={{ marginTop: '24px' }}>
            <a href="tel:400.900.3567">联系电话: 400-900-3567</a>
            <span className={s.nemo}>售前咨询：188188(小鱼号)</span>
            <span>售后服务：288288(小鱼号)</span>
          </p>
        </div>
        <div className={cn(s['fixed-r'], s['for-pc'])}>
          <p id="backTotop" className={cn(s['back'], upBtnVisible && s['visible'])} onClick={onScrollTop}>
            <a href="#" className={s['back-to-top']}></a>
          </p>
          <a className={s['call']}>
            <div className={s['call-box']}>
              <span>售前咨询：400-900-3567转1</span>
              <span>售后服务：400-900-3567转2</span>
            </div>
          </a>

          <a className={s['zixun']} onClick={handleZixunClick}>
            <div className={s['box']}>
              <span>
                售前在线咨询 <br /> 获取产品方案报价
              </span>
            </div>
          </a>

          <a className={s['app-erwei']}>
            <div className={s['box']}>
              <Image src="/v2/images/footer/erwei@2x.png" width="132" height="132" alt="erwei" className={s['erwei-native']} />
              <div className={s.text}>iOS/Android客户端</div>
            </div>
          </a>
        </div>
      </div>

      <div className={s['mob-only-item']}>
        <div className={cn(s['container-fluid'], s['footer'])}>
          <div className={cn(s['container'], s['item-box'])} id="footer">
            <div className={cn(s['row'], s['link-title'])}>
              {mobileFooterList.map((rowItem, index) => {
                return (
                  <div className={s['character-title']} key={index}>
                    {rowItem.map((item, index) => {
                      return (
                        <div key={index}>
                          <CustomLink isNextLink={false} href={item.href} needTarget={item.isJump} onClick={item.handleClick}>
                            {item.text}
                          </CustomLink>
                          {index !== rowItem.length - 1 && <span className={s['left-border']}></span>}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>

            <div id="footerWechatIcon" className={s['wechat-img']} onClick={onShowEerwei}>
              <Image src="/v2/images/footer/wechat.png" alt="weixin" layout="fill" />
            </div>

            <div className={s['footer-mask']} id="footerMask">
              <div className={s['light']} id="light">
                <div className={s['two-bar-codes']}>
                  <Image src="/v2/images/footer/wechat-logo.png" alt="wechat-logo" layout="fill" />
                </div>
                <p>保存或扫描二维码</p>
                <p>关注小鱼易连微信公众号</p>
                <div className={s['c-btns']}>
                  <a id="footerConfirmBtn" className={cn(s['btn'], s[' btn-sure'], s['btn-sure'])}>
                    确定
                  </a>
                </div>
              </div>
            </div>
            <div className={s['footer-phone']}>
              <p className={s['character-phone']}>
                联系电话: <a href="tel:400.900.3567">400-900-3567</a>
              </p>
              <p className={s['character-phone']}>售前咨询：188188(小鱼号)</p>
              <p className={s['character-phone']}>售后服务：288288(小鱼号)</p>
            </div>
          </div>
        </div>
        <div className={s['fixed-r']}>
          <div className={s['callmobile']}>
            <a onClick={() => trackEvent('callPhone', 'click', 'callMobile')} href="tel:400.900.3567">
              <Image src="/v2/images/footer/call.png" width="60" height="60" alt="call" />
            </a>
          </div>
        </div>
      </div>
      <div className={s['copyright']}>
        Copyright © 2024, XYLink Inc.
        <a href="https://beian.miit.gov.cn/" className={s['icp']} rel="noopener noreferrer" target="_blank">
          沪ICP备17003732号-1
        </a>
        <a className={s['record']} href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502042530" target="_blank" rel="noreferrer">
          <span className={s.emblem}>
            <Image src="/v2/images/footer/national-emblem.png" alt="" layout="fill" />
          </span>
          <span>京公网安备11010502042530号</span>
        </a>
      </div>
    </>
  );
}

export default Header;
