declare global {
  interface Window {
    wx: any;
    _hmt: any;
    _vds: any;
  }
}

interface IWxInfo {
  title?: string;
  desc?: string;
  link?: string;
  imgUrl?: string;
  type?: string;
  dataUrl?: string;
  // 其它参数
  appId: string;
  timestamp: number;
  nonceStr: string;
  signature: string;
}

export const wxInit = (wxInfo: IWxInfo) => {
  const {
    title = '小鱼易连官网-掌握音视频核心技术的云视频生态系统公司',
    desc = window.location.href,
    link = window.location.href,
    imgUrl = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/wxshare/f85960b574661cd66697dc10541dfc0b7ad03c092388de3a04778ce4767cf385.png',
    type,
    dataUrl,
    appId,
    timestamp,
    nonceStr,
    signature,
  } = wxInfo;
  const wx = window.wx;
  wx.config({
    debug: false,
    appId,
    timestamp,
    nonceStr,
    signature,
    jsApiList: [
      // 所有要调用的 API 都要加到这个列表中
      'onMenuShareTimeline',
      'onMenuShareAppMessage',
      'onMenuShareQQ',
      'onMenuShareWeibo',
      'onMenuShareQZone',
    ],
  });
  const wxshare = {
    title, // 分享标题
    desc, // 分享描述
    link, // 分享链接
    imgUrl, // 分享图标
    type, // 分享类型,music、video或link，不填默认为link
    dataUrl, // 如果type是music或video，则要提供数据链接，默认为空
    // success: function () {},
    cancel: (err: any) => {
      console.log(err);
    },
  };
  wx.ready(function () {
    wx.onMenuShareTimeline(wxshare);
    wx.onMenuShareAppMessage(wxshare);
    wx.onMenuShareQQ(wxshare);
    wx.onMenuShareWeibo(wxshare);
    wx.onMenuShareQZone(wxshare);
  });
};
