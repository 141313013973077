/**
 * Store LocalStorage
 * @authors Luo-jinghui (luojinghui424@gmail.com)
 * @date  2019-11-01 14:36:12
 */

const serialize = function (value: any): string {
  if (!value) return '';

  let val = '';
  const type = Object.prototype.toString.call(value);
  if (type === '[object Object]' || type === '[object Array]') {
    val = JSON.stringify(value);
  } else {
    val = value;
  }

  return val;
};

export default function getStore() {
  class Store {
    storage = window?.localStorage;
    set(key: string, value: any) {
      if (!value) return;

      this.storage.setItem(key, serialize(value));
    }
    get(key: string) {
      if (!key) return;

      const val: any = this.storage.getItem(key);
      try {
        return JSON.parse(val);
      } catch (e) {
        return val;
      }
    }
    remove(key: string) {
      if (!key) return;

      this.storage.removeItem(key);
    }
    clear() {
      this.storage.clear();
    }
    // 批量删除 array => key 数组
    removeList(array: string[]) {
      for (const key of array) {
        this.storage.removeItem(key);
      }
    }
  }

  return new Store();
}
