import Head from 'next/head';
// import { useRouter } from 'next/router';
import Layout from 'components/layout';
import style from './index.module.scss';
interface IProps {
  statusCode: number;
}

const statusText: { [index: number]: string } = {
  400001: '客户端发生错误',
  404: '抱歉，你访问的页面已不存在',
  500: '服务端发生错误',
};

export default function Error({ statusCode }: IProps) {
  // const router = useRouter();
  // console.log(router);
  return (
    <Layout isErrorPage={true}>
      <Head>
        <title>{statusCode}</title>
      </Head>
      <div className={style.container}>{statusText[statusCode]}</div>
    </Layout>
  );
}
