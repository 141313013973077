// 计算文件大小
export const computeSize = (size: number) => {
  if (size < 1024) {
    return size + 'B';
  }
  size = parseInt(size / 1024 + '', 10);
  if (size < 1024) {
    return size + 'K';
  }
  size = parseInt(size / 1024 + '', 10);
  if (size < 1024) {
    return size + 'M';
  }

  size = Number((size / 1024).toFixed(2));
  return size + 'G';
};

export const getIconName = (fileName: string) => {
  // const suffixArr = ['xls', 'xlsx', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'txt', 'jpg', 'jpeg', 'png'];
  const arr = fileName.split('.');
  const suffix = arr[arr.length - 1];
  if (suffix === 'xls' || suffix === 'xlsx') {
    return 'xls';
  }
  if (suffix === 'pdf') {
    return 'pdf';
  }
  if (suffix === 'doc' || suffix === 'docx') {
    return 'docx';
  }
  if (suffix === 'ppt' || suffix === 'pptx') {
    return 'ppt';
  }
  if (suffix === 'jpg' || suffix === 'jpeg' || suffix === 'png') {
    return 'tupian';
  }
  if (suffix === 'txt') {
    return 'txt';
  }
  return 'unknow';
};

export const isMobile = () => {
  const screenWidth = window.innerWidth ? window.innerWidth : document.body.clientWidth;
  return screenWidth <= 768;
};

export const initFontSize = () => {
  const screenWidth = window.innerWidth ? window.innerWidth : document.body.clientWidth;
  if (screenWidth <= 768) {
    const whdef = 100 / 750;
    const rem = screenWidth * whdef;
    document.documentElement.setAttribute('style', 'font-size: ' + rem + 'px');
  }
  // else {
  //   document.documentElement.setAttribute('style', 'font-size: 100px');
  // }
};

export interface IPlatTest {
  android: boolean;
  ios: boolean;
  weixin: boolean;
  windows: boolean;
  [props: string]: boolean;
}
export const platform = (): IPlatTest => {
  const u = navigator.userAgent;
  return {
    //移动终端浏览器版本信息
    trident: u.indexOf('Trident') > -1, //IE内核
    presto: u.indexOf('Presto') > -1, //opera内核
    webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, //火狐内核
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    ios: !!u.match(/(iPhone|iPad|iPod|iOS)/i), //ios终端
    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
    iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
    iPad: u.indexOf('iPad') > -1, //是否iPad
    webApp: u.indexOf('Safari') === -1, //是否web应该程序，没有头部与底部
    weixin: u.indexOf('MicroMessenger') > -1, //判断是否为微信打开，
    mac: u.indexOf('Mac OS X') > -1,
    windows: u.indexOf('Window') > -1,
    chrome: u.indexOf('Chrome') > -1,
  };
};

// 立即执行
export function throttle(func: any, wait: number) {
  let flag = false;
  return function (...args: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const _this = this;
    if (!flag) {
      func.apply(_this, args);
      flag = true;
      setTimeout(() => {
        flag = false;
      }, wait);
    }
  };
}

//当前页锚点跳转时候刷新页面达到关闭导航栏的效果
export const jumpToAnchorAndReload = (anchorId: string) => {
  window.location.href = `${window.location.origin}${window.location.pathname}#${anchorId}`; // 设置带有当前页面路径和锚点的 URL
  location.reload(); // 刷新页面
};

// 在线咨询聊天框显示
export const handleZixunClick = (e: any) => {
  //阻止href=''时，默认刷新页面的行为
  e.preventDefault();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.qimoChatClick();
};
