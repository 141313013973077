import type { AppProps } from 'next/app';
import Script from 'next/script';
import { action } from 'action/index';
import { wxInit } from 'utils/wxInit';
import { platform } from 'utils/index';
import getStore from 'utils/store';
import { appWithTranslation } from 'next-i18next';
import ErrorBoundary from 'components/errorBoundary';

import '../styles/globals.scss';
import { useEffect } from 'react';
// 性能测量 日志
// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   console.log(metric);
// }

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const referrer = document.referrer;
    const xyReg = /^https:\/\/www.xylink.com/;

    if (referrer.length > 0 && !xyReg.test(referrer)) {
      const referrerInfo = {
        referrer: referrer,
        cacheTime: new Date().getTime(),
      };
      const store = getStore();
      store.set('referrerInfo', referrerInfo);
    }
    // 移除百度统计自动弹出留言框
    // const handleMutation = () => {
    //   const affImRoot = document.getElementById('aff-im-root');
    //   if (affImRoot) {
    //     affImRoot.remove();
    //   }
    //   // 移除元素后停止观察
    //   // observer.disconnect();
    // };

    // // handleMutation();

    // // 创建 MutationObserver 实例，监视子节点的变化
    // const observer = new MutationObserver(handleMutation);

    // // 在整个文档上启动观察，监视子节点的添加和删除
    // observer.observe(document.body, {
    //   childList: true,
    //   subtree: true,
    // });

    // // 在组件卸载时停止观察
    // return () => observer.disconnect();
  }, []);
  // 微信分享
  const wxShare = async () => {
    const customizeWxConfig = pageProps.wxConfig || {};
    const res = await action.getShareConfig();
    if (res.http_code === 200) {
      const params = Object.assign({}, res.result, customizeWxConfig);
      wxInit(params);
    }
  };
  return (
    <ErrorBoundary>
      {/* beforeInteractive相当于defer 仍然会阻塞渲染*/}
      {/* 默认afterInteractive 业务代码都执行完了*/}
      <script src="/v2/initFontSize.js" />
      <Script
        src="/v2/jweixin-1.0.0.js"
        onLoad={() => {
          const plat = platform();
          if (plat.weixin && window.wx) {
            wxShare();
          }
        }}
      />
      <Script
        src="//hm.baidu.com/hm.js?c3575006722ae89193c572c7583cbde3"
        onLoad={() => {
          if (window._hmt) {
            window._hmt.push(['_setAccount', 'c3575006722ae89193c572c7583cbde3']);
          } else {
            console.log('_hmt不存在');
          }
        }}
      />
      {/* 在线咨询 */}
      <Script type="text/javascript" src="https://webchat.7moor.com/javascripts/7moorInit.js?accessId=d65a0dc0-0025-11ee-9a41-ff584a8271ff&autoShow=false&language=ZHCN" async={true}></Script>
      <Component {...pageProps} />
    </ErrorBoundary>
  );
}
export default appWithTranslation(MyApp);
