import { langsText as langsTextPc, langsTextMobile } from 'constants/index';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';
import s from './index.module.scss';
import { isMobile as fnIsMobile } from 'utils/index';
import { useEffect, useMemo, useState } from 'react';
export default function LocaleSwitcher() {
  const router = useRouter();
  const [isMobile, setIsMobile] = useState(false);
  const { pathname, query, asPath, locales, locale: activeLocale } = router;

  useEffect(() => {
    setIsMobile(fnIsMobile());
  }, []);

  const langsText = useMemo(() => {
    return isMobile ? langsTextMobile : langsTextPc;
  }, [isMobile]);
  return (
    <div className={s.container}>
      {locales?.map((locale: string) => {
        return (
          <Link key={locale} href={{ pathname, query }} passHref={true} as={asPath} locale={locale}>
            <span className={cn(s.language, activeLocale === locale && s.active)}>{langsText[locale]}</span>
          </Link>
        );
      })}
    </div>
  );
}
