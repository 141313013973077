/**
 * Axios
 */

import Axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosError } from 'axios';
// import { reportApiError } from 'utils/reportError';
export interface IResponseSuccess {
  code: number;
  data: any;
}

export interface IResponseFailed {
  code: number;
  data: any;
}

class HttpClient {
  public commonOption: AxiosRequestConfig;

  public axios: AxiosInstance;

  constructor(commonOption: AxiosRequestConfig, isServer: boolean) {
    this.commonOption = commonOption;

    Axios.defaults.timeout = 10000;
    // 当前端配置withCredentials=true时, 后端配置Access-Control-Allow-Origin不能为*, 必须是相应地址
    Axios.defaults.withCredentials = false;

    this.axios = Axios.create(commonOption);
    // this.axios.interceptors.request.use((config: any) => {
    //   return Promise.resolve(config);
    // });
    // 添加默认的响应拦截器
    this.axios.interceptors.response.use(
      (response: AxiosResponse): any => {
        if (response.data || response.status === 200) {
          return response.data;
        }
        return {};
      },
      (error: AxiosError): Promise<AxiosError> => {
        // reportApiError(error, isServer);
        console.log('api error ==> ', error);
        return Promise.reject(error);
      }
    );
  }

  public assignOptions = (option?: AxiosRequestConfig) => {
    return Object.assign({}, this.commonOption, option, {
      params: Object.assign({}, this.commonOption.params, option && option.params),
    });
  };

  public get = (url: string, option?: AxiosRequestConfig): any => {
    return this.axios.get(url, this.assignOptions(option)).catch(err => {
      return null;
    });
  };

  public put = (url: string, data?: any, option?: AxiosRequestConfig): any => {
    return this.axios.put(url, data, this.assignOptions(option)).catch(err => {
      return null;
    });
  };

  public post = (url: string, data?: any, option?: AxiosRequestConfig): any => {
    return this.axios.post(url, data, this.assignOptions(option)).catch(err => {
      return null;
    });
  };
}

export default HttpClient;
