import { apiUrlMap } from 'constants/index';
const getBaseURL = (isServer: boolean) => {
  // const env = process.env.NODE_ENV;
  // const isDev = env !== 'production';
  let baseURL;

  const envKey = process.env.envKey;
  if (envKey) {
    // build的时候是有envKey
    baseURL = apiUrlMap[envKey].clientApiUrl;
  } else {
    // development环境 和 start的时候没有 envKey
    baseURL = isServer ? process.env.serverApiUrl : process.env.NEXT_PUBLIC_clientApiUrl;
  }

  return baseURL;
};

export { getBaseURL };
