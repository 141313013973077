//跳转组件: 用于跳转到指定页面
//两种跳转方式:1. 通过a标签跳转 2. 通过next.js提供的Link组件跳转
import Link from 'next/link';

interface IProps {
  //是否使用next.js的Link
  isNextLink: boolean;
  //跳转的地址
  href: string;
  //使用Link时，强制 Link 将 href 属性传递给其子元素
  passHref?: boolean;
  //导航到新页面之后滚动到页面顶部
  scroll?: boolean;
  //子元素:使用Link组件内部必须包含一个可点击的元素，比如 <a> 标签
  children: React.ReactNode | string;
  //自定义类名
  className?: string;
  styles?: React.CSSProperties;
  //是否在新窗口打开
  needTarget?: boolean;
  //点击事件
  onClick?: (e?: any) => void;
}

export default function CustomLink(props: IProps) {
  const { href = '', isNextLink = false, passHref = false, scroll = true, children, className = '', needTarget, styles = {}, onClick } = props;
  const linkProps = needTarget ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  return isNextLink ? (
    //注意:Link没有className属性
    <Link href={href} passHref={passHref} scroll={scroll}>
      {children}
    </Link>
  ) : (
    <a href={href} className={className} onClick={onClick} {...linkProps} style={styles}>
      {children}
    </a>
  );
}
