import React from 'react';
import Error from 'components/error';
// import { reportClientError } from 'utils/reportError';
interface IProps {
  children: JSX.Element | React.ReactNode;
}
interface IState {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    // 设置state
    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    console.log('client error ==> ', error);
    console.log('errorInfo ==> ', errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return <Error statusCode={400001} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
