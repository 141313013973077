import HttpClient from './HttpClient';
import { getBaseURL } from './config';
import getStore from 'utils/store';
import { platform } from 'utils/index';

class Action {
  public store: any;

  public client: HttpClient;

  constructor(isServer: boolean) {
    this.client = new HttpClient(
      {
        baseURL: getBaseURL(isServer),
        timeout: 10000,
      },
      isServer
    );
  }

  public getAnnouncementList = async (current: number, size: number) => {
    return this.client.get(`/api/rest/website/foreground/information`, {
      params: {
        current,
        size,
        infoType: 'NOTICE',
      },
    });
  };

  public getAnnouncementDetail = async (id: string) => {
    return this.client.get(`/api/rest/website/foreground/information/detail`, {
      params: {
        id,
      },
    });
  };

  public getAnnouncementDetailViewCount = async (id: string) => {
    return this.client.get(`/api/rest/website/foreground/information/viewCount`, {
      params: {
        id,
      },
    });
  };

  public getResource = async (id: string, resourceId: string) => {
    return this.client.get(`/api/rest/website/foreground/information/resource`, {
      params: {
        id,
        resourceId,
      },
    });
  };
  public getResourceForSupportCenter = async (resourceId: string) => {
    return this.client.get(`/api/rest/website/foreground/oss/download/url`, {
      params: {
        resourceId,
      },
    });
  };

  // 获取微信分享参数
  public getShareConfig = async () => {
    const url = encodeURIComponent(window.location.href);
    return this.client.get(`https://cloud.xylink.com/api/rest/wxshare/getconfigvalue`, {
      params: {
        url,
      },
    });
  };

  // 使用指南类型列表
  public getGuideCategory = async () => {
    return this.client.get(`/api/rest/website/foreground/guide/product`, {
      params: {},
    });
  };

  // 使用指南类型列表
  public getGuideList = async (productId: string) => {
    return this.client.get(`/api/rest/website/foreground/guide/title`, {
      params: {
        productId,
        current: 1,
        size: 1,
      },
    });
  };

  // 使用指南类型列表
  public getGuideInfo = async (id: string) => {
    return this.client.get(`/api/rest/website/foreground/guide`, {
      params: {
        id,
      },
    });
  };

  // 常见问题类型列表
  public getFaqCategory = async () => {
    return this.client.get(`/api/rest/website/foreground/question/product`, {
      params: {},
    });
  };

  // 常见问题列表
  public getFaqList = async (id: string, current: number, size: number) => {
    return this.client.get(`/api/rest/website/foreground/question/title`, {
      params: {
        productId: id,
        current,
        size,
      },
    });
  };

  // 常见问题详情
  public getFaqInfo = async (id: string) => {
    return this.client.get(`/api/rest/website/foreground/question`, {
      params: {
        id,
      },
    });
  };

  public getFaqViewCount = async (id: string) => {
    return 20;
    // return this.client.get(`/api/rest/website/foreground/question`, {
    //   params: {
    //     id,
    //   },
    // });
  };

  // 搜索
  public getSearchFaqList = async (current: number, size: number, keyword: string) => {
    return this.client.get(`/api/rest/website/foreground/question/search`, {
      params: {
        current,
        size,
        keyword,
      },
    });
  };

  // 搜索
  public getHotWord = async () => {
    return this.client.get(`/api/rest/website/foreground/question/hot/search/word/recommend`, {
      params: {},
    });
  };

  public getSalesProcess = async () => {
    return this.client.get(`/api/rest/website/foreground/sales`, {
      params: {},
    });
  };

  // 获取客户端下载地址及版本信息
  public getClientInfo = async (platform: string) => {
    return this.client.get('/api/rest/en/version', {
      params: {
        platform,
        vcsSource: 'home',
      },
      baseURL: 'https://cloud.xylink.com',
    });
  };

  // 统计搜索
  public statisticsSearch = async (type: string) => {
    const store = getStore();
    const referrerInfo = store.get('referrerInfo') || { referrer: '', cacheTime: 0 };
    const referrer = referrerInfo.referrer;
    const duration = new Date().getTime() - referrerInfo.cacheTime;
    if (referrer.length > 0 && duration <= 86400000) {
      this.client.post(
        '/api/rest/website-service/v1/web/source/statistics',
        {
          referer: referrer,
          accessType: type,
        },
        {
          baseURL: 'https://cloud.xylink.com',
        }
      );
    }
  };
  // 新闻中心
  public getNewsList = async (current: number, size: number) => {
    return this.client.get(`/api/rest/website/foreground/news/page`, {
      params: {
        current,
        size,
      },
    });
  };

  public getNewsDetail = async (id: string) => {
    return this.client.get(`/api/rest/website/foreground/news/detail/info`, {
      params: {
        id,
      },
    });
  };

  public getNewsViewCount = async (id: string) => {
    return this.client.get(`/api/rest/website/foreground/news/view/count`, {
      params: {
        id,
      },
    });
  };

  // 首页新闻
  public getHomeNews = async () => {
    return this.client.get(`/api/rest/website/foreground/news/home/page`);
  };

  // 立即试用&联系销售
  public submit = async (data: any, type: string) => {
    const _hmt = window._hmt || [];
    _hmt.push(['_trackEvent', 'submitBtn', 'click', type]);
    const plat = platform();
    if (type === 'contact') {
      if (plat.mobile) {
        _hmt.push(['_trackEvent', 'submitBtn', 'click', 'contactMobile']);
      } else {
        _hmt.push(['_trackEvent', 'submitBtn', 'click', 'contactPc']);
      }
    } else if (type === 'try') {
      if (!plat.mobile) {
        _hmt.push(['_trackEvent', 'submitBtn', 'click', 'tryPc']);
      }
    }

    const result = await this.client.post(`/api/rest/website/external/application/add/v1`, data);

    if (!result) {
      if (plat.mobile) {
        _hmt.push(['_trackEvent', '申请试用', '移动端']);
      } else {
        _hmt.push(['_trackEvent', '申请试用', 'PC端']);
      }
    }

    // const statisticsType = type === 'try' ? 'TRIAL_NOW' : 'CONTACT_SALES';
    // this.statisticsSearch(statisticsType);
    return result;
  };
}

const action = new Action(false);
const actionServer = new Action(true);

export { action, actionServer };
