import CustomLink from 'components/link';
import cn from 'classnames';
import style from '../header/index.module.scss';

interface navItem {
  needTitle?: boolean;
  name?: string;
  url?: string;
  external?: boolean;
  test?: boolean;
  show?: boolean;
  locale?: string;
  children?: {
    name: string;
    url: string;
    external?: boolean;
  }[];
}
export const navBarItem = (list: navItem[]) => {
  {
    return list.map((item: navItem, key: number) => {
      return (
        <li key={item.name || key}>
          {item.needTitle &&
            (item.external ? (
              <a href={item.url}>{item.name}</a>
            ) : (
              item.url && (
                <div className={cn(style.title, item.locale === 'en-US' && style.enTitle)}>
                  <CustomLink isNextLink={false} href={item.url}>
                    {item.name}
                  </CustomLink>
                  {item.children && <span className={style.open}>&gt;</span>}
                </div>
              )
            ))}
          <ul className={cn(item.show && style.items, item.test && style.test)}>
            {item.children &&
              item.children.map((childrenItem: { name: string; url: string; external?: boolean }) => {
                return (
                  <li key={childrenItem.name}>
                    {childrenItem.external ? (
                      <a href={`${childrenItem.url}`}>{childrenItem.name}</a>
                    ) : (
                      <CustomLink isNextLink={false} href={`${childrenItem.url}`}>
                        {childrenItem.name}
                      </CustomLink>
                    )}
                  </li>
                );
              })}
          </ul>
        </li>
      );
    });
  }
};
