import Head from 'next/head';
import styles from './index.module.scss';
import { ReactNode, useState } from 'react';
import Header from 'components/header';
import Footer from 'components/footer';
import HeaderI18n from 'components/headerI18n';
import FooterI18n from 'components/footerI18n';
import cn from 'classnames';
import { pageConfig } from 'constants/index';

interface IProps {
  children: ReactNode;
  home?: boolean;
  isErrorPage?: boolean;
  layoutClass?: string;
  headerClass?: string;
  isNeedI18n?: boolean;
}
declare global {
  interface Window {
    wx: any;
    _vds: any;
    _hmt: any;
  }
}

export default function Layout({ children, isErrorPage, layoutClass, headerClass, isNeedI18n = false }: IProps) {
  const [showApp, setShowApp] = useState(false);

  const onToggleApp = (showApp: boolean) => {
    setShowApp(showApp);
  };
  return (
    <div className={cn(styles.container, showApp && styles.bigPadding, layoutClass)}>
      <Head>
        <title>{pageConfig.title}</title>
        <link rel="icon" href="/v2/favicon.ico" />
        <meta name="keywords" content={pageConfig.keywords} />
        <meta name="description" content={pageConfig.desc} />
        <meta
          property="og:image"
          content={`/v2/images/xy-logo.png?theme=light&md=0&fontSize=75px&images=https%3A%2F%2Fassets.vercel.com%2Fimage%2Fupload%2Ffront%2Fassets%2Fdesign%2Fnextjs-black-logo.svg`}
        />
        <meta name="og:title" content={pageConfig.title} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="renderer" content="webkit|ie-comp|ie-stand" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content="Web App" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="baidu-site-verification" content="KZGH01AAo2" />
      </Head>
      {isNeedI18n ? <HeaderI18n onToggleApp={onToggleApp} headerClass={headerClass} /> : <Header onToggleApp={onToggleApp} headerClass={headerClass} />}
      <div className={cn(styles.main, isErrorPage && styles.center)}>{children}</div>
      {isNeedI18n ? <FooterI18n /> : <Footer />}
    </div>
  );
}
